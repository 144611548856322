"use strict";

const CHROME_BASE_EXT_LINK = "https://chrome.google.com/webstore/detail/";
const EDGE_BASE_EXT_LINK = "https://chrome.google.com/webstore/detail/";
const MAIL_LIST = ["hen@imali.media"];

class ProductsData {
  chromeExtID = null;
  edgeExtID = null;
}

class PrametersCamp {
  cid;
  fid;
  channel;
  pclkid;
}

class ProductClass {
  #domain = null;
  #browser = null;
  #qParams = null;
  #click_id = null;
  #params = new PrametersCamp();
  #products = new ProductsData();
  #event_engine = "";

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  constructor(ext = {}, dic = {}, event_engine = "", domain = "") {
    this.#domain = domain;
    this.#event_engine = event_engine;
    this.#qParams = {};
    window.location.search
      .substring(1)
      .split("&")
      .map((val) => {
        const temp = val.split("=");
        const key = temp[0];
        let obj = {};
        obj[key] = temp[1];
        this.#qParams[key] = temp[1];
      });

    this.#params.cid = this.#qParams[dic["cid"]];
    this.#params.fid = this.#qParams[dic["fid"]];
    this.#params.channel = this.#qParams[dic["channel"]];
    this.#params.pclkid = this.#qParams[dic["pclkid"]];
    const d = new Date();

    this.#click_id = this.uuidv4(
      this.#params.cid + "." + d.toUTCString() + "." + Math.random() * 100000
    ); // create imali cid

    this.detectBrowser(ext);
  }

  #setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/; domain=." + this.#domain;
  }

  #getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  detectBrowser(ext = null) {
    const agent = window.navigator.userAgent.toLocaleLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        this.#browser = "MS_Edge";
        this.#products.edgeExtID = ext.edge;
        break;
      case agent.indexOf("edg/") > -1:
        this.#browser = "CB_Edge"; //( chromium based)
        this.#products.edgeExtID = ext.edge;
        break;
      case agent.indexOf("chrome") > -1 && !!window.chrome:
        this.#browser = "Chrome";
        this.#products.chromeExtID = ext.chrome;
        break;
      case agent.indexOf("opr") > -1 && !!window.opr:
        this.#browser = "Opera";
        this.#products = null;
        break;
      case agent.indexOf("trident") > -1:
        this.#browser = "MS_IE";
        this.#products = null;
        break;
      case agent.indexOf("firefox") > -1:
        this.#browser = "Firefox";
        this.#products = null;
        break;
      case agent.indexOf("safari") > -1:
        this.#browser = "Safari";
        this.#products = null;
        break;
      default:
        this.#browser = "other";
        this.#products = null;
        break;
    }
  }

  Install() {
    this.#setCookie("first_inst", "1", 365);
    if (this.#getCookie("iev") == "") {
      this.#setCookie("iev", "1", 365);
      let s = document.createElement("img");
      s.width = 1;
      s.height = 1;
      s.style.position = "absolute";
      s.style.top = "0";
      s.style.right = "0";
      s.src =
        this.#event_engine +
        "?cid=" +
        encodeURIComponent(this.#getCookie("sub1")) +
        "&channel=" +
        encodeURIComponent(this.#getCookie("sub2")) +
        "&fid=" +
        encodeURIComponent(this.#getCookie("sub3")) +
        "&clkid=" +
        encodeURIComponent(this.#getCookie("clkid")) +
        "&tsp=" +
        encodeURIComponent(this.#getCookie("tsp")) +
        "&at=install";
      document.body.appendChild(s);
    }
  }

  InstantClick() {
    if (this.#products === null) {
      return;
    }
    try {
      let fid = this.#getCookie("sub3");

      if (!fid || fid === "0") {
        let cid = this.#params.cid || "";
        let fid = this.#params.fid || "";
        let channel = this.#params.channel || "";
        let pclkid = this.#params.pclkid || "";
        const d = new Date();

        const clkid = this.#click_id;
        const tsp = new Date().getTime();

        const timeToSaveCookies = 30;

        this.#setCookie("clkid", clkid, timeToSaveCookies);
        this.#setCookie("tsp", tsp, timeToSaveCookies);
        this.#setCookie("sub1", cid, timeToSaveCookies); // cid
        this.#setCookie("sub2", channel, timeToSaveCookies); // channel
        this.#setCookie("sub3", fid, timeToSaveCookies); // fid
        this.#setCookie("sub4", clkid, timeToSaveCookies);

        if (cid.length > 0) {
          // new added if there is no cid dont do event.
          if (this.#event_engine.length > 0) {
            let s = document.createElement("img");
            s.width = 1;
            s.height = 1;
            s.style.position = "absolute";
            s.style.top = "0";
            s.style.right = "0";
            s.src =
              this.#event_engine +
              "?cid=" +
              encodeURIComponent(cid) +
              "&channel=" +
              encodeURIComponent(channel) +
              "&fid=" +
              encodeURIComponent(fid) +
              "&clkid=" +
              encodeURIComponent(clkid) +
              "&pclkid=" +
              encodeURIComponent(pclkid) +
              "&sub4=" +
              encodeURIComponent(clkid) +
              "&tsp=" +
              encodeURIComponent(tsp) +
              "&at=click";
            document.body.appendChild(s);
          }
        }
      }
    } catch (e) {
      this.#sendMail(
        "Product monetization error (SPDS)",
        "An error occurred in the SPDS system that monitors calls for products that use EV_ENGINE.  \n " +
        e.toString()
      );
    }

    // new link opener
    let link;
    switch (this.#browser) {
      case "Chrome":
        if (this.#products.chromeExtID == null) {
          return;
        }
        link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID
        break;
      case "MS_Edge":
        if (this.#products.edgeExtID != null) {
          link = EDGE_BASE_EXT_LINK + this.#products.edgeExtID;
        } else if (this.#products.chromeExtID != null) {
          link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID;
        } else {
          return;
        }
        // window.open(link, "_blank");
        break;
      case "CB_Edge":
        if (this.#products.edgeExtID != null) {
          link = EDGE_BASE_EXT_LINK + this.#products.edgeExtID;
        } else if (this.#products.chromeExtID != null) {
          link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID;
        } else {
          return;
        }
        // window.open(link, "_blank");
        break;
      default:
        link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID
        break;
    }

    setTimeout(() => {
      window.location.replace(link);
    }, 300);
  }

  Click() {
    if (this.#products === null) {
      return;
    }
    try {
      let fid = this.#getCookie("sub3");

      if (!fid || fid === "0") {
        let cid = this.#params.cid || "";
        let fid = this.#params.fid || "";
        let channel = this.#params.channel || "";
        let pclkid = this.#params.pclkid || "";
        const d = new Date();

        const clkid = this.#click_id;
        const tsp = new Date().getTime();

        const timeToSaveCookies = 30;

        this.#setCookie("clkid", clkid, timeToSaveCookies);
        this.#setCookie("tsp", tsp, timeToSaveCookies);
        this.#setCookie("sub1", cid, timeToSaveCookies); // cid
        this.#setCookie("sub2", channel, timeToSaveCookies); // channel
        this.#setCookie("sub3", fid, timeToSaveCookies); // fid
        this.#setCookie("sub4", clkid, timeToSaveCookies);

        if (cid.length > 0) { // new added if there is no cid dont do event.
          if (this.#event_engine.length > 0) {
            let s = document.createElement("img");
            s.width = 1;
            s.height = 1;
            s.style.position = "absolute";
            s.style.top = "0";
            s.style.right = "0";
            s.src = this.#event_engine +
              "?cid=" +
              encodeURIComponent(cid) +
              "&channel=" +
              encodeURIComponent(channel) +
              "&fid=" +
              encodeURIComponent(fid) +
              "&clkid=" +
              encodeURIComponent(clkid) +
              "&pclkid=" +
              encodeURIComponent(pclkid) +
              "&sub4=" +
              encodeURIComponent(clkid) +
              "&tsp=" +
              encodeURIComponent(tsp) +
              "&at=click"
            document.body.appendChild(s);
          }
        }
      }
    } catch (e) {
      this.#sendMail(
        "Product monetization error",
        "An error occurred in the system that monitors calls for products that use EV_ENGINE.  \n " +
        e.toString()
      );
    }
    let link;
    switch (this.#browser) {
      case "Chrome":
        if (this.#products.chromeExtID == null) {
          return;
        }
        window.open(
          CHROME_BASE_EXT_LINK + this.#products.chromeExtID,
          "_blank"
        );
        break;
      case "MS_Edge":
        if (this.#products.edgeExtID != null) {
          link = EDGE_BASE_EXT_LINK + this.#products.edgeExtID;
        } else if (this.#products.chromeExtID != null) {
          link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID;
        } else {
          return;
        }
        window.open(link, "_blank");
        break;
      case "CB_Edge":
        if (this.#products.edgeExtID != null) {
          link = EDGE_BASE_EXT_LINK + this.#products.edgeExtID;
        } else if (this.#products.chromeExtID != null) {
          link = CHROME_BASE_EXT_LINK + this.#products.chromeExtID;
        } else {
          return;
        }
        window.open(link, "_blank");
        break;
      default:
        window.open(
          CHROME_BASE_EXT_LINK + this.#products.chromeExtID,
          "_blank"
        );
        break;
    }
  }

  Impression() {
    let s = document.createElement("img");
    s.width = 1;
    s.height = 1;
    s.style.position = "absolute";
    s.style.top = "0";
    s.style.right = "0";
    s.src =
      this.#event_engine +
      "?cid=" +
      encodeURIComponent(this.#params.cid) +
      "&channel=" +
      encodeURIComponent(this.#params.channel) +
      "&fid=" +
      encodeURIComponent(this.#params.fid) +
      "&clkid=" +
      encodeURIComponent(this.#click_id) +
      "&pclkid=" +
      encodeURIComponent(this.#params.pclkid) +
      "&sub4=" +
      encodeURIComponent(this.#click_id) +
      "&at=impression";
    document.body.appendChild(s);
  }

  Uninstall() {
    let fid = this.#getCookie("sub3");

    if (fid && fid != "0") {
      let s = document.createElement("img");
      s.width = 1;
      s.height = 1;
      s.style.position = "absolute";
      s.style.top = "0";
      s.style.right = "0";
      s.src =
        this.#event_engine +
        "?cid=" +
        encodeURIComponent(this.#getCookie("sub1")) +
        "&channel=" +
        encodeURIComponent(this.#getCookie("sub2")) +
        "&fid=" +
        encodeURIComponent(fid) +
        "&clkid=" +
        encodeURIComponent(this.#getCookie("clkid")) +
        "&at=uninstall";
      document.body.appendChild(s);
    }
  }

  #sendMail(msg_subject, msg_body) {
    let URL =
      " https://fx5m5o1zwa.execute-api.us-east-1.amazonaws.com/init/mail-sender";

    const data = {
      toEmail: MAIL_LIST,
      body: msg_body,
      subject: msg_subject,
    };

    const xhr = new XMLHttpRequest();
    xhr.open("POST", URL);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");

    xhr.send(JSON.stringify(data));
  }
}

const ProductObj = new ProductClass();

export { ProductObj, PrametersCamp, ProductsData, ProductClass };
